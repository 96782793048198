export const retryTimesProp = 'retryTimes'
export const succeededProp = 'succeeded'
export const failedProp = 'failed'
export const maxRetryCountProp = 'maxRetryCount'
export const onRetryProp = 'onRetry'
export const onSuccessProp = 'onSuccess'
export const onFailProp = 'onFail'
export const domainProp = 'domain'
export const styleImageNoImportant = 'styleImageNoImportant'
export const innerProxyProp = '_assetsRetryProxy'
export const innerOnloadProp = '_assetsRetryOnload'
export const innerOnerrorProp = '_assetsRetryOnerror'
export const scriptTag = 'script'
export const linkTag = 'link'
export const hookedIdentifier = 'data-assets-retry-hooked'
export const ignoreIdentifier = 'data-assets-retry-ignore'
export const retryIdentifier = 'data-retry-id'
export const win = window
export const doc = window.document
export const ElementCtor = win.HTMLElement
export const ScriptElementCtor = win.HTMLScriptElement
export const StyleElementCtor = win.HTMLStyleElement
export const LinkElementCtor = win.HTMLLinkElement
export const ImageElementCtor = win.HTMLImageElement
